// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "_header";
@import "main";
//@import blocks
@import "sidebar";
@import "_footer";
