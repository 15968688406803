/* SideBar CSS */
.sidebar {
	background-color: #e6e6e6;
	color: #6d6d6d;
	height: 100%;
	border: thin solid gray {
    padding-top: 1em; } }
#social-widget {
	padding: 1em;
	margin: 0 auto; }
.sidebar {
    after: {
        content: "";
        background: black;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50%;
        width: 1px; } }
#fbook {
    width: 40px;
    height: 40px;
    background-image: url('/img/social-network.png');
    background-position: -60px -11px;
    background-size: 400%;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 30px #333); }
#twitter {
    width: 40px;
    height: 40px;
    background-image: url('/img/social-network.png');
    background-position: -60px -110px;
    background-size: 400%;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 30px #333); }
#google {
    width: 40px;
    height: 40px;
    background-image: url('/img/social-network.png');
    background-position: -111px -11px;
    background-size: 400%;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 30px #333); }
