
/* Footer Style Contribution */
#footer {
  background: #2c2c2c;
  text-align: center;
  width: 100%;
  margin-top: .2em;
  color: black;
  height: 97px; }
#links {
  a {
    color: #bcbbb6;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 300; }
  color: #bcbbb6; }

#disclaimer {
  color: #91908b;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 12px;
  a {
    color: #1780c3; } }

/* Social Media Footer Icon Styling */
.right {
  min-height: 70px;
  float: right; }
.center #navlist {
  display: inline-block;
  margin: 0 auto;
  background: #2c2c2c; }
.foottxt {
  margin-top: -3rem; }
#navlist {
  position: relative; }
#navlist li {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 50px; }
#navlist li, #navlist a {
  height: 44px;
  display: block; }
#fbico {
  margin-left: .4rem; }
#instaico {}
#twittico {
  margin-right: .4rem; }
/* Back to top Button */
/**********************************************************************************************/
.go-top {
  position: fixed;
  bottom: 1px;
  right: 1%;
  text-decoration: none;
  color: white;
  background-color: darkgray;
  font-size: 12px;
  padding: 1em;
  display: none;
  text-align: right;
  z-index: 50;
  &:hover {
    background-color: rgba(255,255,255, 0.6); } }
/**********************************************************************************************/
/* Media Queries */
