body {
	font-family: 'PT Serif', serif; }
a {
	font-family: 'CenturyGothic', sans-serif; }
ul {
	display: block;
	margin-left: -2em; }
h3 {
	color: black;
	font-family: CenturyGothic, sans-serif; }
label {
	width: 25rem; }
input {
	length: 35rem; }
.alignright {
	float: right; }
.alignleft {
	float: left; }
.aligncenter {
	display: block;
	margin: 0 auto; }
.btn-default {
	background-color: skyblue;
	color: darkblue;
	font-size: 18px;
	line-height: 30px;
	font-weight: 600;
	font-family: Roboto;
	font-style: normal;
	text-align: center;
	vertical-align: middle;
	background-repeat: no-repeat;
	box-shadow: 4px 4px 5px darkgray; }
a.btn-default:hover {
	background-color: orange; }
a.btn-default:hover {
	color: darkblue; }
.brand {
	max-width: 250px;
	max-height: 200px; }
.button {
	display: block;
	width: 70%;
	margin: 0 auto; }
.circ {
	width: 65%; }
.disclaimer {
	color: white;
	padding: 10px 10px 0px !important;
	vertical-align: bottom;
	color: #91908b;
	&:hover {
		cursor: pointer; }
	&:hover {
		color: white; } }
#disctxt {
	display: none;
	position: absolute;
	top: 10%;
	left: 2%;
	color: white;
	background-color: #333;
	border-radius: 5%;
	width: 350px;
	z-index: 2000;
	padding: 2em;
	margin-top: 3em; }
#disctxt p {
	margin-top: 1em; }
#disctxt h4 {
	margin: 0 0;
	font-family: 'CenturyGothic', sans-serif; }
#disc_close {
	position: relative;
	padding-left: 50px;
	color: #3097D1;
	font-size: 1.5em;
	&:hover {
		cursor: pointer; } }
.ratebox {
	float: right;
	background-color: ghostwhite;
	width: 165px;
	padding: .2em;
	color: black; }
.rbutton {
	margin-bottom: 1em; }
.coffer {
	font-family: 'CenturyGothic', sans-serif;
	text-align: center; }
.container {
    background: white;
    border: 2px solid black;
    max-width: 1000px; }
.hint {
	content: "";
	height: 1em; }
#links a {
	font-family: 'CenturyGothic', sans-serif; }
.oblocks {
	margin-top: .5em;
	margin-left: .4em;
	margin-right: .4em;
	padding: 0 0;
	border-top: .1em solid darkgray;
	/* border-top: .2em solid black */ }
.otext {
	text-align: left;
	padding-bottom: 1em; }
.rate {
	clear: left; }
.rating {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: midnightblue; }
.pleft {
	padding-left: 6px; }
.pright {
	padding-right: 6px; }
.roffer h4 {
	color: black;
	font-family: CenturyGothic, sans-serif; }
.reset {
	display: none; }
.reviews {
	border-left: thin solid darkgray;
	background-color: ghostwhite; }
.social {
	margin-top: 10%;
	right: 1em; }
.socialsm {
	margin-left: 22%;
	margin-top: 3%; }
.sidesocial {}
.sidebar {
    background-color: ghostwhite; }
.tag_title {
	color: rgb(157,157,157);
	background-color: #222;
	border-radius: 15px;
	margin-top: 2em;
	margin-left: 11%;
	font-family: CenturyGothic, sans-serif; }
.testi {
    border-top: thin solid lightgray;
    margin: 1.5em 0; }
.testi h5 {
    color: black; }
.testimonials {
	margin: .3em; }
@media (min-width: 1200px) {
	.head_img {
		margin-left: 195px !important; } }
@media (min-width: 992px) and (max-width: 1200) {
	.btn {
		width: 80% !important; }
	.head_img {
		width: 200px; }
	.ratearea {
		margin-top: -25px; }
	.rratearea {
		margin-top: -25px !important; }
	.rbutton {
		margin-top: -35px !important;
		width: 65% !important; }
	.btn {
	    width: 55%;
	    margin-left: auto;
	    margin-right: auto; }
	.sidesocial {
	    margin-left: 7% !important; } }
@media (min-width: 768px) {
	.button {
		padding-top: 1em;
		padding-bottom: 1em; }
	.header {
		height: 455px; }
	.socialsm {
		display: none !important; }
	.navbar {
		margin-top: 415px;
		width: 100%; }
	.navbar-brand {
		line-height: 22px !important; }
	.ratearea {
		padding-top: 1em;
		margin-left: 2em; }
	.rratearea {
		padding-left: 4.5rem; }
	.btn {
		display: block; }
	.brand {
		padding: 1rem; }
	.rbutton {
		margin-top: 1px; } }
@media (max-width: 992px) {
	.ratearea {
		padding-top: 1em; }
	.head_img {
		margin-left: 65px;
		margin-top: 6rem;
		width: 200px; }
	.sidesocial {
		margin-left: 40%; } }
@media  (max-width: 770px) {
	h3 {
		text-align: center;
		color: black;
		font-family: CenturyGothic, sans-serif; }
	.btn {
		width: 90%; }
	.brand, .rbrand {
		padding-top: 1em;
		margin-left: auto;
		margin-right: auto;
		display: block; }
	.go-top {
		right: 2% !important; }
	.header {
		height: 385px;
		background-image: url('../img/test5_tb.jpg'); }
	.head_img {
		margin-left: 8%;
		margin-top: 5.5rem;
		width: 190px; }
	.navbar-brand {
		padding: 5px 12px !important;
		line-height: 38px !important; }
	.navbar {
		margin-top: 345px;
		width: 100%; }
	.ratearea {
		margin: 1px auto;
		margin-bottom: 0.5em;
		text-align: center; }
	.rbutton {
		margin-top: 0; }
	.socialsm {
		margin-left: 32%; }
	.sidesocial {
		display: none; }
	.callouts {
		display: none; }
	.deallinks {
		padding-top: 1em; }
	.tag_title {
		display: none; } }
@media (max-width: 600px) {
	.navbar {
		margin-top: 335px;
		width: 100%; } }
@media (max-width: 420px) {
	.btn {
		width: 100%;
		font-size: small; }
	.button {
		width: 85%; }
	.go-top {
		right: 4% !important; }
	.header {
		background-image: url('../img/test5_xs.jpg');
		height: 225px; }
	.head_img {
		width: 140px;
		margin-top: 0.5rem;
		left: 1%; }
	.navbar {
		margin-top: 210px;
		width: 100%; }
	.navbar-brand {
		font-size: 14px; }
	.oblocks {
		padding-bottom: 1rem; }
	.socialsm {
		margin-left: 22%; }
	.sidesocial {
		display: none; }
	.otext {
		font-size: smaller; }
	.rbutton {
		margin: .5em auto;
		width: 100%; }
	.rratearea {
		margin-top: 1em; }
	.coffer h4 {
		font-size: 16px; }
	.brand {
		margin: 0 auto;
		display: block;
		width: 155px; }
	.rbrand {
		margin: 1px auto;
		display: block; }
	.roffer {
		margin-left: -15px; } }
@media (max-width: 375px) {
	.navbar {
		margin-top: 179px; }
	#links a {
		font-size: smaller; } }
