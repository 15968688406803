.header {
	background-image: url('../img/test5.jpg');
	background-repeat: no-repeat;
	background-position: top center;
	background-color: black  /* For browsers that do not support gradients */;
	height: 455px; }
.head_img {
	position: absolute;
	margin-top: 5.5rem;
	margin-left: 17%;
	width: 225px;
	z-index: 1600; }
.navbar {
	font-family: 'CenturyGothic', sans-serif;
	border-radius: 0;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px !important;
	margin-top: 415px;
	width: 70%;
	z-index: 1500; }
.navbar-brand {
	line-height: 40px; }
a.navbar-brand {
	font-family: 'CenturyGothic', sans-serif; }
.navbar ul {
	float: right;
	display: block;
	z-index: 1200; }
